import { Component, OnInit } from '@angular/core';
import { SecurityService } from '../../security/security.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../shared/data.service';
import { MatSnackBar } from '@angular/material';
import { RentalProductsCatagory } from '../../shared/Interfaces/ILogin';
import { ProductAvalibilityResponse, responseMsg, ICustomerProfile } from '../../shared/Interfaces/IInterfaces';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.css']
})
export class AddProductComponent implements OnInit {

  constructor(private titleService: Title, private ngxUiLoaderService: NgxUiLoaderService,private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router, private dataService: DataService,
    private securityService: SecurityService) {
    dataService.getCurrentCart('addProd');
    this.orderId = parseInt(this.route.snapshot.params['id']);
    this.titleService.setTitle("TheRentalGuys.Ca - Add Product");

  }

  orderId;
  franchiseId;

  searchTerm;

  stepOneDone = false;

  stepTwoDone = true;

  productsList: RentalProductsCatagory[] = [];

  filteredProductsList: RentalProductsCatagory[] = [];

  selected: RentalProductsCatagory;

  totalDays;

  prodAvb: ProductAvalibilityResponse;

  isProductAvaliable = false;

  customerId;
  customerProfile: ICustomerProfile = new ICustomerProfile();

  ngOnInit() {    

    this.franchiseId = localStorage.getItem("franchiseId");

    this.dataService.allRentalProductList(this.franchiseId)
      .subscribe(
        (data: RentalProductsCatagory[]) => {
          this.productsList = data;
          this.filteredProductsList = data;
        },
        (err: any) => console.log(err),
        () => {
          console.log("Data Recived");
        }
    );

    this.customerId = Number(localStorage.getItem("customerId"));
    let franchsie = Number(localStorage.getItem("currentOrder_franchiseId"));

    this.dataService.currentBilling(this.customerId, franchsie)
      .subscribe(
        (data: ICustomerProfile) => {
          this.customerProfile = data;
        },
        (err: any) => {
          this.ngxUiLoaderService.stop();
          console.log(err);
        },
        () => {
          console.log("Data Recived");
        }
      );

  }


  search()
  {
    let term = this.searchTerm.toLowerCase().trim();
    if (term == '') {
      this.filteredProductsList = this.productsList;
    } else {
      this.filteredProductsList = this.productsList.filter(option => option.name.toLowerCase().includes(term));
    }
  }

  selectProduct(item:RentalProductsCatagory)
  {
    this.stepOneDone = true;
    this.stepTwoDone = false;
    this.selected = item;
  }

  selectProductAgain()
  {
    this.stepOneDone = false;
    this.stepTwoDone = true;
    this.selected = null;
    this.isProductAvaliable = false;
    this.totalDays = 0;
  }

  checkAvalibility()
  {
    if (this.totalDays > 336) {
      let message = "Sorry maximum limit is 336";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 2000,
      });

      this.totalDays = 336;
    }
    
      if (this.totalDays != null || this.totalDays != undefined) {
        this.dataService.checkAvalibility(this.orderId, this.selected.productId, this.totalDays)
          .subscribe(
            (data: ProductAvalibilityResponse) => {

              this.prodAvb = data;

              if (data.totalSerialAvb > 0) {
                let message = "Product Avaliable";
                let action = "Ok";
                this._snackBar.open(message, action, {
                  duration: 2000,
                });
                this.isProductAvaliable = true;
              }
              else {
                let message = "Sorry the product is not avaliable for selected days";
                let action = "Ok";
                this._snackBar.open(message, action, {
                  duration: 2000,
                });
              }

            },
            (err: any) => console.log(err),
            () => {
              console.log("Data Recived");
            }
          );
      }
      else {
        let message = "Please enter days";
        let action = "Ok";
        this._snackBar.open(message, action, {
          duration: 2000,
        });
      }
    
  }

  addProductToOrder()
  {
    this.dataService.addProductToOrder(this.orderId, this.selected.productId, this.totalDays)
      .subscribe(
        (data: responseMsg) => {
          let message = "Successfully Updated";
          let action = "Ok";
          this._snackBar.open(message, action, {
            duration: 2000,
          });

          this.router.navigate(['/ordersDetail/' + this.orderId]);
        },
        (err: any) => console.log(err),
        () => {
          console.log("Data Recived");
        }
      );
  }

}
