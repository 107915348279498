import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RentalProductsCatagory } from '../../shared/Interfaces/ILogin';
import { ProductAvalibilityResponse, responseMsg } from '../../shared/Interfaces/IInterfaces';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityService } from '../../security/security.service';
import { DataService } from '../../shared/data.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-add-product-to-qoute',
  templateUrl: './add-product-to-qoute.component.html',
  styleUrls: ['./add-product-to-qoute.component.css'], encapsulation: ViewEncapsulation.None
})
export class AddProductToQouteComponent implements OnInit {

  constructor(
    private titleService: Title, private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router, private dataService: DataService,
    private securityService: SecurityService) {
    dataService.getCurrentCart('productToQoute');
    this.orderId = parseInt(this.route.snapshot.params['id']);
    this.titleService.setTitle("TheRentalGuys.Ca - Add Product To Quote");
  }

  orderId;
  franchiseId;

  searchTerm;

  stepOneDone = false;

  stepTwoDone = true;

  productsList: RentalProductsCatagory[] = [];

  filteredProductsList: RentalProductsCatagory[] = [];

  selected: RentalProductsCatagory;

  totalDays=1;

  prodAvb: ProductAvalibilityResponse;

  isProductAvaliable = false;

  quantity=1;

  ngOnInit() {

    this.franchiseId = localStorage.getItem("quote_franchiseId");

    this.dataService.allRentalProductList(this.franchiseId)
      .subscribe(
        (data: RentalProductsCatagory[]) => {
          this.productsList = data;
          this.filteredProductsList = data;
        },
        (err: any) => console.log(err),
        () => {
          console.log("Data Recived");
        }
      );

  }


  search() {
    let term = this.searchTerm.toLowerCase().trim();
    if (term == '') {
      this.filteredProductsList = this.productsList;
    } else {
      this.filteredProductsList = this.productsList.filter(option => option.name.toLowerCase().includes(term));
    }
  }

  selectProduct(item: RentalProductsCatagory) {
    this.stepOneDone = true;
    this.stepTwoDone = false;
    this.selected = item;
  }

  selectProductAgain() {
    this.stepOneDone = false;
    this.stepTwoDone = true;
    this.selected = null;
    this.isProductAvaliable = false;
    this.totalDays = 1;
    this.quantity = 1;
  }

  checkAvalibility() {
    if (this.totalDays > 336) {
      let message = "Sorry maximum limit is 336";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 2000,
      });

      this.totalDays = 336;
    }

    if (this.quantity > this.selected.totalUnits) {
      let message = `Sorry maximum limit is ${this.selected.totalUnits}`;
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 2000,
      });

      this.quantity = this.selected.totalUnits;
    }

    if (this.totalDays != null || this.totalDays != undefined) {
      this.dataService.getCostProduct(this.quantity, this.selected.productId, this.totalDays)
        .subscribe(
          (data: ProductAvalibilityResponse) => {

            this.prodAvb = data;
            this.isProductAvaliable = true;
            //if (data.totalSerialAvb > 0) {
            //  let message = "Product Avaliable";
            //  let action = "Ok";
            //  this._snackBar.open(message, action, {
            //    duration: 2000,
            //  });
            //  this.isProductAvaliable = true;
            //}
            //else {
            //  let message = "Sorry the product is not avaliable for selected days";
            //  let action = "Ok";
            //  this._snackBar.open(message, action, {
            //    duration: 2000,
            //  });
            //}

          },
          (err: any) => console.log(err),
          () => {
            console.log("Data Recived");
          }
        );
    }
    else {
      let message = "Please enter days";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 2000,
      });
    }

  }

  //ProductID,string ProductType,long QouteID,int TotalDay,int quantity
  addProductToQoute() {
    this.dataService.addProductToQoute(this.orderId, this.selected.productId, this.totalDays,this.quantity,'RP')
      .subscribe(
        (data: void) => {
          let message = "Successfully Added";
          let action = "Ok";
          this._snackBar.open(message, action, {
            duration: 2000,
          });

          this.router.navigate(['/qouteDetails/' + this.orderId]);
        },
        (err: any) => console.log(err),
        () => {
          console.log("Data Recived");
        }
      );
  }

}
